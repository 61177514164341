import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { marker as _t } from '@biesbjerg/ngx-translate-extract-marker';

import { ProgressBarService } from './pages/shared/progress-bar/progress-bar.service';
import { TitlePageService } from './core/services/title-page/title-page.service';
import { SplashScreenService } from './core/services/splash-screen/splash-screen.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private titlePageService: TitlePageService,
    private _splashScreenService: SplashScreenService,
    public progressBarService: ProgressBarService,
    public translate: TranslateService,
  ) {
  }

  pageTitle = _t('Inicio');

  ngOnInit() {
    this.renamePage();

    const defaultLanguage: any = { name: 'Español', image: 'assets/images/flags/es.svg', lang: 'es' };
    if (localStorage.getItem('language')) {
      let language = JSON.parse(localStorage.getItem('language'));
      language = language ? language : defaultLanguage;
      this.translate.setDefaultLang(language.lang);
      this.translate.use(language.lang);
    } else {
      this.translate.setDefaultLang(defaultLanguage.lang);
      localStorage.setItem('language', JSON.stringify(defaultLanguage));
    }
  }

  renamePage() {
    this.titlePageService.setTitle(
      this.pageTitle,
      this.titlePageService.SERVICE_BUSSINESS,
    );
  }
}
