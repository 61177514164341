// loader.interceptors.ts
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProgressBarService } from 'src/app/pages/shared/progress-bar/progress-bar.service';


@Injectable({
  providedIn: 'root',
})
export class LoaderInterceptorService implements HttpInterceptor {
  constructor(public progressBar: ProgressBarService) {
  }

  private requests: HttpRequest<any>[] = [];

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    if (this.requests.length > 0) {
      this.progressBar.show();
    } else {
      this.progressBar.hide();
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.requests.push(req);
    this.progressBar.show();
    return Observable.create(observer => {
      const subscription = next.handle(req)
        ?.subscribe({
          next: (event) => {
            if (event instanceof HttpResponse) {
              this.removeRequest(req);
              observer.next(event);
            }
          },
          error: (err) => {
            // alert('error returned');
            this.removeRequest(req);
            observer.error(err);
          },
          complete: (): void => {
            this.removeRequest(req);
            observer.complete();
          }
        })
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }

}
