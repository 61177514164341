import { NgModule } from '@angular/core';
import { CurrencyConvPipe } from './currency-conv.pipe';

@NgModule({
  declarations: [
    CurrencyConvPipe,
  ],
  exports: [
    CurrencyConvPipe,
  ],
})
export class CurrencyConvModule {
}
