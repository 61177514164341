import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { LoggedInUserService } from '../../core/services/loggedInUser/logged-in-user.service';
import { AuthenticationService } from '../../core/services/authentication/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  constructor(private router: Router,
              private authenticationService: AuthenticationService,
              private loggedInUserService: LoggedInUserService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkAccess();
  }

  checkAccess(): boolean {
    const loggedInUser = this.loggedInUserService.getLoggedInUser();

    if (loggedInUser && loggedInUser.role) {
      if (loggedInUser.role === 'supervisor' || loggedInUser.role === 'admin') {
        this.router.navigate(['backend/dashboard']);
      } else if (loggedInUser.role === 'messenger') {
        this.router.navigate(['backend/dashboard']);
      } else {
        this.router.navigate(['backend/dashboard']);
      }
    } else {
      this.authenticationService.logout();
      return true;
    }
    return true;
  }
}
