import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutGuard } from './pages/backend/commons/guards/layout.guard';
import { AuthenticationGuard } from './pages/authentication/authentication.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'backend',
    loadChildren: () => import('./pages/backend/backend.module').then((m) => m.BackendModule),
    canActivate: [LayoutGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/authentication/authentication.module').then((m) => m.AuthenticationModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: '**',
    redirectTo: 'backend/error/404',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
