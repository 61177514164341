import { Injectable } from '@angular/core';
import { IUser } from '../../classes/user.class';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoggedInUserService {
  constructor() {
    const data = this.getLoggedInUser();
    if (data) {
      this.loggedInUser = { ...data };
    }
  }

  private token: null;
  $loggedInUserUpdated = new Subject<IUser>();
  $showCountDown = new Subject<any>();
  loggedInUser: IUser = null;

  public setNewAvatar(user) {
    this.loggedInUser.avatar = user.avatar;
    this.setLoggedInUser(user);
    this.$loggedInUserUpdated.next(this.loggedInUser);
  }

  public getUserAgency() {
    return this.loggedInUser.AgencyId;
  }

  public getLoggedInUser(): any {
    const userStorage = localStorage.getItem('u_');
    if (userStorage) {
      const decrypted = userStorage;  //this.encrDrecService.get(environment.key, userStorage);
      return JSON.parse(decrypted);
    } else {
      return null;
    }
  }

  public setLoggedInUser(user: any) {
    if (user) {
      this.setToken(user.token);
      const dataString = JSON.stringify(user);
      const encriptedData = dataString; // TODO: this.encrDrecService.set(environment.key, dataString);
      localStorage.setItem('u_', encriptedData);
    }
    this.loggedInUser = user;
  }

  public updateUserProfile(user) {
    if (user) {
      this.setToken(user.token);
      this.loggedInUser = Object.assign(this.loggedInUser, user);
    } else {
      this.setToken(null);
      this.loggedInUser = null;
    }
    this.setLoggedInUser(this.loggedInUser);
    this.$loggedInUserUpdated.next(this.loggedInUser);
  }

  public setToken(token) {
    this.token = token;
  }

  public getTokenOfUser(): any {
    return this.token || null;
  }

  public isAdmin(): boolean {
    return this.getLoggedInUser() && this.getLoggedInUser().role === 'admin';
  }

  public isClient(): boolean {
    return this.getLoggedInUser() && this.getLoggedInUser().role === 'client';
  }

  public getLanguage() {
    return JSON.parse(localStorage.getItem('language'));
  }
}
