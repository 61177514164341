import { Injectable } from '@angular/core';
import { ShowToastrService } from '../show-toastr/show-toastr.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AbstractControl, UntypedFormArray } from '@angular/forms';
import { VALID_IMAGES } from '../../constants/valid-image';
import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { marker as _t } from '@biesbjerg/ngx-translate-extract-marker';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(
    public sanitizer: DomSanitizer,
    private showToastr: ShowToastrService,
    private httpClient: HttpClient,
  ) {
  }

  urlImage = environment.imageUrl;

  private generator(): string {
    return `${this.S4()}${this.S4()}-${this.S4()}-${this.S4()}-${this.S4()}-${this.S4()}${this.S4()}${this.S4()}`;
  }

  private S4(): string {
    // tslint:disable-next-line:no-bitwise
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }

  public getUrlImages(): string {
    return environment.imageUrl;
  }

  getControls(form: any, field: string) {
    return form.get(field) as UntypedFormArray;
  }

  getImage(imageUrl: string): Observable<Blob> {
    return this.httpClient.get(this.urlImage, { responseType: 'blob' });
  }

  parserLanguage(item, language) {
    if (item[language] && item[language].length) {
      return item[language];
    } else if (item.en && item.en.length) {
      return item.en;
    } else {
      return item.es;
    }
  }

  hasPermissions(array, text): boolean {
    const valueIndex = array.findIndex(item => {
      return item === text;
    });
    return valueIndex >= 0;
  }

  generateUuid(): string {
    let tempId = '';
    tempId = this.generator();
    return tempId;
  }

  keyPressAlpha(event) {
    const inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keyPressAlphaSpaceNumbers(event) {
    const inp = String.fromCharCode(event.keyCode);
    const charCode = (event.which) ? event.which : event.keyCode;

    if ((/[a-zA-Z]/.test(inp)) || (inp === ' ') || (charCode <= 57 && charCode >= 48)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keyPressAlphaSpace(event) {
    const inp = String.fromCharCode(event.keyCode);
    if ((/[a-zA-Z 0-9,.-]/.test(inp))) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keyPressDateNumbers(event) {
    const inp = String.fromCharCode(event.keyCode);
    if ((/[0-9/-]/.test(inp))) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keyPressNumbers(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  keyPressDecimalNumbers(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 46 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  totalKmItinerary(segments: any[]) {
    if (!segments || segments.length === 0) {
      return 0;
    } else if (segments.length === 1) {
      return this.buildDistance(segments[0]);
    }
    return segments.reduce((a, b) => {
      const distanceA = this.buildDistance(a);
      const distanceB = this.buildDistance(b);
      return distanceA + distanceB;
    });
  }

  buildDistance(data) {
    let distance;
    if (data.constructor === Object) {
      if (data?.Segment?.distance > 0) {
        distance = data?.Segment?.distance;
      } else {
        distance = 0;
      }
    } else {
      distance = data;
    }
    return distance;
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  validatePhone(cellphone) {
    return !(cellphone.length !== 8 || cellphone[0] !== '5' || isNaN(Number(cellphone)));
  }

  formatDate(date) {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return day + ' ' + monthNames[monthIndex] + ' ' + year;
  }

  errorHandle(error, nomenclator?, action?) {
    let msg = nomenclator
      ? action
        ? 'Error ' + action + ' ' + nomenclator
        : 'Error ' + action
      : _t(
        'La respuesta del servidor ha fallado, chequee su conexión de red o póngase en contacto con un administrador de sistema.');
    if (error.errors && error.errors.length) {
      error.errors.forEach(e => {
        this.showToastr.showError(e.title || e.message, e.field);
      });
      return;
    } else if (error.error && error.error.errors) {
      error.error.errors.forEach(e => {
        this.showToastr.showError(e.title || e.message, e.field);
      });
      return;
    } else if (error.error && typeof error.error !== 'string' && error.error.length) {
      error.error.forEach(e => {
        this.showToastr.showError(e.title || e.message, e.field);
      });
      return;
    } else if (error.error) {
      msg = error.error.message;
      if (!msg) {
        msg = 'La respuesta del servidor ha fallado, chequee su conexión de red o póngase en contacto con un administrador de sistema.';
      }
      this.showToastr.showError(msg, 'Error');
    }
  }

  public getSafeImage(url: string) {
    return this.sanitizer.bypassSecurityTrustStyle(`url(${url})`);
  }

  public isObjectEquals(x, y): boolean {
    if (x === y) {
      return true;
    }

    if (!(x instanceof Object) || !(y instanceof Object)) {
      return false;
    }

    if (x.constructor !== y.constructor) {
      return false;
    }

    for (let p in x) {
      if (!x.hasOwnProperty(p)) {
        continue;
      }

      if (!y.hasOwnProperty(p)) {
        return false;
      }

      if (x[p] === y[p]) {
        continue;
      }

      if (typeof x[p] !== 'object') {
        return false;
      }

      if (!this.isObjectEquals(x[p], y[p])) {
        return false;
      }
    }
    for (let p in y) {
      if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
        return false;
      }
    }
    return true;
  }

  /**
   *
   * @param color #FFFFFF
   */
  static getContrastColor(color) {
    const r = parseInt(color.substr(1, 2), 16);
    const g = parseInt(color.substr(3, 2), 16);
    const b = parseInt(color.substr(5, 2), 16);
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 128) ? 'black' : 'white';
  }

  static buildInitialDate(dataDate) {
    const date = UtilsService.getOnlyDate(dataDate);
    const elem = UtilsService.getDateFromOnlyDateString(date);
    elem.setHours(0, 0, 0);

    return elem;
  }

  static buildEndDate(dataDate) {
    const date = UtilsService.getOnlyDate(dataDate);
    const elem = UtilsService.getDateFromOnlyDateString(date);
    elem.setHours(23, 59, 59);

    return elem;
  }

  static clearFormArray = (formArray: UntypedFormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };

  static isInvalidImage = (type: string) => {
    const e = VALID_IMAGES.findIndex(item => item === type);
    return e === -1;
  };

  static isDateInRange(startDate, endDate, day) {
    return this.getDateAndTime(startDate) <= this.getDateAndTime(day)
      && this.getDateAndTime(endDate) >= this.getDateAndTime(day);
  }

  static mergeBooleanObject(object1?, object2?) {
    const newObject = {};
    if (object1) {
      Object.keys(object1).forEach(key => {
        const value1 = object1 ? object1[key] : false;
        const value2 = object2 ? object2[key] : false;
        newObject[key] = value1 || value2;
      });
    }
    return newObject;
  }

  static deleteProperty(obj, prop) {
    if (obj[prop]) {
      delete obj[prop];
    }
  }

  static getDiffInYears(date1, date2) {
    const a = moment(date1);
    const b = moment(date2);
    return b.diff(a, 'years');
  }

  // static getTimeInSeconds(time) {
  //   time = time.split(":");
  //   return (parseInt(time[0]) * 3600) + (parseInt(time[1]) * 60) + parseInt(time[2])
  // }

  static getMilitaryHour(data) {
    return data.substr(0, 5);
  }

  static getTimeFromMilitaryHour(data) {
    const dateStrings = data.split(':');
    if (dateStrings.length) {
      let a = ' AM';
      let hour = parseInt(dateStrings[0]);
      const minutes = dateStrings[1];
      if (hour > 12) {
        hour = hour - 12;
        a = ' PM';
        return hour.toString() + ':' + minutes + a;
      } else {
        return hour.toString() + ':' + minutes + a;
      }
    }
  }

  static getGroupSupplements(ContractSupplementPrices) {
    const supplements = [];
    ContractSupplementPrices.map(item => {
      if (supplements.length) {
        const element = supplements.find(e => {
          return item.Supplement.id === e.id;
        });
        if (!element) {
          supplements.push(item.Supplement);
        } else {
          if (!element.SupplementGroup) {
            element.SupplementGroup = [];
          }

          element.SupplementGroup.push(item);
        }
      } else {
        supplements.push(item.Supplement);
        supplements[0].SupplementGroup = [];
        supplements[0].SupplementGroup.push(item);
      }
    });
    return supplements;
  }

  static getCloneGroupSupplements(ContractSupplementPrices) {
    const supplements = [];
    if (ContractSupplementPrices) {
      ContractSupplementPrices.map(item => {
        if (supplements.length) {
          const element = supplements.find(e => {
            return item.Supplement.id === e.id;
          });
          if (!element) {
            supplements.push(item.Supplement);
          } else {
            if (!element.SupplementGroup) {
              element.SupplementGroup = [];
            }

            element.SupplementGroup.push({
              ...item.SupplementGroup,
              price: item.price,
            });
          }
        } else {
          supplements.push(item.Supplement);
          supplements[0].SupplementGroup = [];
          supplements[0].SupplementGroup.push({
            ...item.SupplementGroup,
            price: item.price,
          });
        }
      });
    }
    return supplements;
  }

  static getControlName(control: AbstractControl): string | null {
    const formGroup = control.parent.controls;
    return Object.keys(formGroup).find(name => control === formGroup[name]) || null;
  }

  static addDays(date?, day?) {
    if (date) {
      return new Date(date + (3600 * 1000 * 24));
    }
    return new Date(Date.now() + day * 24 * 60 * 60 * 1000);
  }

  static getTimePretty(time) {
    let hours: number;
    let minutes: number;
    hours = parseInt((time / 3600) + '', 10);
    time = time - hours * 3600;
    minutes = parseInt((time / 60) + '', 10);
    time = time - minutes * 60;
    // return hours + ':' + minutes + ':' + time;
    return (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes);
  }

  static twelveHourTime(time) {
    time = UtilsService.getTimePretty(time);
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' am' : ' pm'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  static humanizeDuration(time) {
    let hours: number;
    let minutes: number;
    hours = parseInt((time / 3600) + '', 10);
    time = time - hours * 3600;
    minutes = parseInt((time / 60) + '', 10);
    time = time - minutes * 60;
    // return hours + ':' + minutes + ':' + time;
    return (hours < 10 ? '0' + hours : hours) + 'h : ' + (minutes < 10 ? '0' + minutes : minutes) + 'm';
  }

  static getOnlyDate(date: string): string {
    const dateStrings = date.split('T');
    if (dateStrings.length === 2) {
      return dateStrings[0];
    }
  }

  /**
   *
   * @param date YYYY-MM-DD
   */
  static getDateFromOnlyDateString(date) {
    const elem = date.split('-');
    const d = new Date();
    d.setFullYear(elem[0], elem[1] - 1);
    d.setDate(elem[2]);

    return d;
  }

  static getDateFrenchFormat(date) {
    const elem = date.split('-');
    const d = new Date();
    d.setFullYear(elem[0], elem[1] - 1);
    d.setDate(elem[2]);

    return moment(d).format('DD/MM/YYYY');
  }

  static getDateAndTime(date, hour?) {
    const elem = date.split('-');
    const d = new Date();
    d.setFullYear(elem[0], elem[1] - 1);
    d.setDate(elem[2]);

    if (hour) {
      const elemHour = hour.split(/[/ :]/);
      d.setHours(elemHour[0], elemHour[1], 0);
    }

    return d;
  }

  static getDiffInHoursMinutes(timeStart, timeEnd) {
    const hourDiff = timeEnd - timeStart;
    const diffHrs = Math.floor((hourDiff % 86400000) / 3600000);
    let diffMins = Math.round(((hourDiff % 86400000) % 3600000) / 60000);
    diffMins = diffMins + (diffHrs * 60);
    return {
      diffHrs,
      diffMins: diffMins % 60,
    };
  }

  static getDiffBetweenDatesInDays(dayOne, dayTwo) {
    const oneDay = 1000 * 60 * 60 * 24;

    let Result = Math.round(dayOne.getTime() - dayTwo.getTime()) / (oneDay);

    if (Result < 0) {
      Result = Result * -1;
    }

    return +Result.toFixed(0);
  }

  static compareHours(date1, date2) {
    const d1 = new Date();
    d1.setHours(parseInt(date1.split(':')[0]));
    d1.setMinutes(parseInt(date1.split(':')[1]));

    const d2 = new Date();
    d2.setHours(parseInt(date2.split(':')[0]));
    d2.setMinutes(parseInt(date2.split(':')[1]));

    return d1 > d2;
  }

  static hasPermission(array, text) {
    if (array) {
      const valueIndex = array.findIndex((item) => {
        return item === text;
      });

      return valueIndex >= 0;
    }

    return false;
  }

  static getDiffInDays(departureDay, timeStart, timeEnd) {
    const secDiff = timeEnd - timeStart;
    const secStartstr = UtilsService.getSecondsInTime(timeStart);

    const elem = departureDay.split('-');
    const d = new Date();
    d.setFullYear(elem[0], elem[1] - 1);
    d.setDate(elem[2]);

    d.setHours(parseInt(secStartstr.split(':')[0]));
    d.setMinutes(parseInt(secStartstr.split(':')[1]));
    d.setSeconds(parseInt(secStartstr.split(':')[2]));

    const m = moment(new Date(d));
    m.add(secDiff * 1000, 'milliseconds');

    return {
      diffDate: m.format('DD/MM/YYYY'),
      diffTime: m.format('hh:mm a'),
    };
  }

  // date example YYYY-MM-DD
  static buildDateFromApiString(date) {
    const elem = date.split('-');
    const d = new Date();
    d.setFullYear(parseInt(elem[0]), parseInt(elem[1]) - 1, parseInt(elem[2]));
    return d;
  }

  static addHours(time, seconds, BusStops, SelectedBusStops) {
    const elem = time.split(/[/ :]/);
    const d = new Date();
    d.setHours(elem[0], elem[1], 0);
    // add seconds
    d.setSeconds(d.getSeconds() + seconds);

    BusStops.forEach(bus => {
      SelectedBusStops.forEach(bus2 => {
        if (bus.id === bus2) {
          d.setSeconds(d.getSeconds() + bus.duration);
        }
      });
    });

    return ((d.getHours() < 10 ? '0' + d.getHours() : d.getHours()) + ':' + (d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()));
  }

  static isObjectEquals(x, y): boolean {
    if (x === y) {
      return true;
    }

    if (!(x instanceof Object) || !(y instanceof Object)) {
      return false;
    }

    if (x.constructor !== y.constructor) {
      return false;
    }

    for (const p in x) {
      if (!x.hasOwnProperty(p)) {
        continue;
      }

      if (!y.hasOwnProperty(p)) {
        return false;
      }

      if (x[p] === y[p]) {
        continue;
      }

      if (typeof x[p] !== 'object') {
        return false;
      }

      if (!this.isObjectEquals(x[p], y[p])) {
        return false;
      }
    }
    for (const p in y) {
      if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
        return false;
      }
    }
    return true;
  }

  static parseDuration(time) {
    const elem = time.split(/[/ :]/);
    const d = new Date();
    d.setHours(elem[0], elem[1], 0);

    return elem[0] + ':' + elem[1];
  }

  static getTimeInSeconds(timeString) {
    timeString = timeString.split(':');
    if (timeString.length > 3) {
      throw new Error('Hay que implementar esto');
    }
    let index = 0;
    let totalTime = 0;
    for (let i = timeString.length - 1; i >= 0; i--) {
      totalTime += +(timeString[i]) * Math.pow(60, index + 1);
      index++;
    }
    return totalTime;
  }

  // errorHandle(error, nomenclature?, action?) {
  //   let msg = nomenclature
  //     ? action
  //       ? 'Error ' + action + ' ' + nomenclature
  //       : 'Error ' + action
  //     : `Server response failed, check your connection to the network, or contact the administrators`;
  //   if (error.errors && error.errors.length) {
  //     msg = error.errors.map((item) => item.title || item.message);
  //   } else if (error.error.errors) {
  //     msg = error.error.errors.map((item) => item.title || item.message);
  //   } else if (error.error && error.error.length) {
  //     msg = error.error.map((item) => item.title || item.message);
  //   } else {
  //     msg = error.error.message;
  //   }
  //   this.showToastr.showError(msg, 'Error', 9000);
  // }

  static getSecondsInTime(seconds) {
    const hours = seconds / 3600; // una hora tiene 3600 segundos
    seconds = seconds % 3600;
    const minutes = seconds / 60;
    const minutesS = ('000' + minutes).slice(-2);

    seconds = seconds % 60;
    const secondsS = ('000' + seconds).slice(-2);
    return hours + ':' + minutesS + ':' + secondsS;
  }

  static resolvePropertyByPath(obj: any, path: string[]) {
    return path.reduce((prev, curr) => {
      return prev ? prev[curr] : null;
    }, obj || self);
  }

  static getMapFromArray(array: any[], key?) {
    let map;
    if (key) {
      map = array.reduce((acc, item) => acc.set(item[key], item), new Map());
    } else {
      map = array.reduce((acc, item) => acc.set(item.id, item), new Map());
    }
    return map;
  }
}
