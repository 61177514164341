import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root',
})
export class ShowToastrService {

  constructor(
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {

  }

  showError(error, secundary?, timeout?) {
    timeout = (timeout) ? timeout : 5000;
    secundary = secundary ? this.translate.instant(secundary) : '';


    this.toastr.error(
      this.translate.instant(error),
      secundary,
      {
        timeOut: timeout,
        progressBar: true,
        positionClass: 'toast-bottom-right',
      },
    );

  }

  showSucces(msj, secundary?, timeout?) {
    timeout = (timeout) ? timeout : 5000;
    secundary = secundary ? this.translate.instant(secundary) : '';

    this.toastr.success(
      this.translate.instant(msj),
      secundary,
      {
        timeOut: timeout,
        progressBar: true,
        positionClass: 'toast-bottom-right',
      },
    );

  }

  showInfo(msj, secundary?, timeout?) {
    timeout = (timeout) ? timeout : 5000;
    secundary = secundary ? this.translate.instant(secundary) : '';

    this.toastr.info(
      this.translate.instant(msj),
      secundary,
      {
        timeOut: timeout,
        progressBar: true,
        positionClass: 'toast-bottom-right',
      },
    );

  }


}
