import {Environment} from '@interfaces/environment';
import packageJson from '../../package.json';

export const environment: Environment = {
  production: true,
  apiUrl: 'https://apiautos.havanatursa.com/',
  securityUrl: 'https://apiseg.havanatursa.com/v1/',
  url: 'https://adminautos.havanatursa.com/',
  imageUrl: 'https://cdnturismo.havanatursa.com/',
  timeToResetSession: 14400000,
  domain: 'havanatursa.com',
  language: 'es',
  agencyName: 'Havanatur S.A.',
  serviceBusiness: 'Backend Renta de Autos',
  defaultCurrency: 'EUR',
  amountOfCurrencies: ['USD', 'EUR', 'RUB'],
  defaultMarket: {
    lat: 23.131268,
    lng: -82.3582717,
  },
  key: 'Havanatur*Carros${}45',
  config: {
    allowLoginV2: true,
    hasCompareAvailability: true,
    hasVehicles: false,
    vehicles: false,
    hasTransturReservation: true,
    carAgency: false,
    hasConciliate: true,
    hasAvailabilityReservation: false,
    CanAjustCarAvailability: false,
    hasCarAvailabilityDisabled: false,
    hasOtherAgencies: false,
    hasEnrichedClient: false,
    CanExtend: true,
    canDownloadConfirmedVoucher: true,
    canViewTranstur: false,
    overdraftReport: false
  },
  publicVapidKey: 'BCTHSzCLVuGWI3ElWDTk5YuL8j9Fe9_CHgSZylM_kvPDU4j40NOmN76i-RowmL5r4Ux6jFLTwiqtwaN9lETCXXE',
  gdsServices: [
    {
      icon: 'apartment',
      id: 'hotel',
      route: 'https://adminhoteles.havanatursa.com',
      name: 'Hoteles',
      isActive: true
    },
    {
      icon: 'flight',
      id: 'flight',
      name: 'Charter',
      route: 'https://adminvuelos.havanatursa.com/',
      isActive: true
    },
    {
      icon: 'local_play',
      id: 'experiences',
      name: 'Experiencias',
      route: 'https://adminexperiencias.havanatursa.com/',
      isActive: true
    },
    {
      icon: 'local_taxi',
      id: 'car',
      route: 'https://adminautos.havanatursa.com/',
      name: 'Autos',
      isActive: false
    },
    {
      icon: 'house',
      id: 'house',
      name: 'Casas',
      isActive: false
    },
    {
      icon: 'airport_shuttle',
      id: 'transfer',
      name: 'Transfer',
      route: 'https://admintransfer.havanatursa.com/',
      isActive: true
    }
  ],
  min_amount_days_toReserve: 2,
  min_amount_days_toCollect: 4,
  max_amount_days_toCollect: 60,

  min_amount_days_toReserve_cuco: 1,
  min_amount_days_toCollect_cuco: 1,
  max_amount_days_toCollect_cuco: 365,
  logo_voucher: 'assets/icons/128.png',
  imgError: 'assets/images/imgError.png',
  enzonaResponseOk: 'backend/reservations',
  enzonaResponseCancel: 'backend/buscar',
  version: packageJson.version,
}
