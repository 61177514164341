import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import { NgModule } from '@angular/core';
import {MAT_DATE_LOCALE, MatNativeDateModule, MatOptionModule} from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '@environments/environment';
import { ErrorInterceptorService } from '@interceptors/error-interceptor.service';
import {LanguageInterceptor} from "@interceptors/language.interceptor";
import { LoaderInterceptorService } from '@interceptors/loader-interceptor.service';
import { TokenInterceptorService } from '@interceptors/token-interceptor.service';
import { ProgressBarModule } from '@pages/shared/progress-bar/progress-bar.module';
import { HttpLoaderFactory } from '@services/translate-factory/translate-loader';
import { UpdateControllerModule } from '@services/updates-controller/update-controller.module';
import {MatDatepickerModule} from "@angular/material/datepicker";
import { ReporteAutosDiasComponent } from '@pages/reporte-autos-dias/reporte-autos-dias.component';
import {CurrencyConvModule} from "@services/currency/currency-conv.module";
import {FlexModule} from "@angular/flex-layout";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {MatDividerModule} from "@angular/material/divider";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {ReactiveFormsModule} from "@angular/forms";
import {MatIconModule} from "@angular/material/icon";
import {RemoveAriaOwnsModule} from "@directives/remove-aria-owns/remove-aria-owns.module";

registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [AppComponent, ReporteAutosDiasComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    UpdateControllerModule,
    NgxSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      maxOpened: 1,
    }),
    AppRoutingModule,
    NgxMaterialTimepickerModule.setLocale('en-US'),
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ProgressBarModule,
    MatProgressSpinnerModule,
    CurrencyConvModule,
    FlexModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatIconModule,
    RemoveAriaOwnsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true})
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true,
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: LanguageInterceptor,
    //   multi: true,
    // },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'es',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
