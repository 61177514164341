import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

import { LoggedInUserService } from '../loggedInUser/logged-in-user.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})

export class AuthenticationService {

  constructor(private httpClient: HttpClient,
              private loggedInUserService: LoggedInUserService,
              private router: Router) {
  }

  userUrl = environment.securityUrl + 'auth/login';
  userLogout = environment.securityUrl + 'auth/logout';
  urlForgot = environment.securityUrl + 'auth/forgot';
  urlChangePass = environment.securityUrl + 'auth/change-pass';
  userUrlLocal = environment.apiUrl + 'auth/login';
  userLogoutLocal = environment.apiUrl + 'auth/logout';
  urlForgotLocal = environment.apiUrl + 'auth/forgot';
  urlChangePassLocal = environment.apiUrl + 'auth/change-pass';

  login(user: string, password: string) {
    const base64EncodedPw = btoa(user + ':' + password);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + base64EncodedPw,
      }),
      username: user,
      password: password,
    };

    if (!environment.config.allowLoginV2) {
      return this.httpClient.get<any>(this.userUrlLocal, httpOptions);
      // return this.httpClient.post<any>(this.userUrlLocal, httpOptions);
    }

    if (environment.config.allowLoginV2) {
      return this.httpClient.get<any>(this.userUrl, httpOptions);
      // return this.httpClient.post<any>(this.userUrl, httpOptions);zzzz
    }

    return this.httpClient.get<any>(this.userUrl, httpOptions);
  }

  getProfile(): Observable<any> {
    return this.httpClient.get(this.userUrlLocal);
    // return this.httpClient.get(this.userUrl);
  }

  loginByAccount(token: string): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'language': 'es',
        'Authorization': token,
      }),
      Authorization: token,
    };

    return this.httpClient.get<any>(this.userUrl, httpOptions);
  }

  setLogout(): void {
    this.logout().subscribe(
      () => {
        this.clearStorage();
      },
      () => {
        this.clearStorage();
      },
    );
  }

  passForgot(body): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('email', body.email);
    return this.httpClient.get<any>(this.urlForgot, { params: httpParams });
  }

  changePass(body): Observable<any> {
    return this.httpClient.post<any>(this.urlChangePass, body);
  }

  clearStorage() {
    this.removeCookies();
    localStorage.removeItem('u_');
    this.loggedInUserService.setLoggedInUser(null);
    localStorage.clear();
    this.router.navigate(['/auth']).then(r => false);
  }

  removeCookies() {
    const res = document.cookie;
    const multiple = res.split(';');
    for (let i = 0; i < multiple.length; i++) {
      const key = multiple[i].split('=');
      document.cookie = key[0] + ' =; expires = Thu, 01 Jan 1970 00:00:00 UTC';
    }
  }

  logout(): Observable<any> {
    return this.httpClient.get(this.userLogout);
  }

  getUser(): any {
    return JSON.parse(localStorage.getItem('u_'));
  }

  isLoggedIn(): boolean {
    return this.getUser() !== null;
  }
}

// export const AUTH_PROVIDERS: Array<any> = [
//   {
//     provide: AuthenticationService,
//     useClass: AuthenticationService,
//   },
// ];

