import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { LoggedInUserService } from '@services/loggedInUser/logged-in-user.service';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private loggedInUserService: LoggedInUserService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const userLocal = this.loggedInUserService.getLoggedInUser();

    let lang: string = 'es';
    if ('language' in localStorage) {
      lang = JSON.parse(localStorage.getItem('language')).lang;
    }

    if (userLocal) {
      request = request.clone({
        setHeaders: {
          Authorization: userLocal.token,
          language: lang,
          version: environment.version,
          appType: 'WebBack',
        },
      });
      return next.handle(request);
    } else {
      request = request.clone({
        setHeaders: {
          language: lang,
          version: environment.version,
          appType: 'WebBack',
        },
      });
      return next.handle(request);
    }
  }

}
