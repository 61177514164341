<!-- PROGRESS BAR -->
<app-progress-bar></app-progress-bar>
<ngx-spinner bdColor="rgba(51,51,51,0.60)"></ngx-spinner>
<div *ngIf="(progressBarService._visible|async)" class="loading-shade-full"
     style="background:rgba(0, 0, 0, 0.02) !important;">
  <mat-spinner *ngIf="(progressBarService._visible|async)">
  </mat-spinner>
</div>
<!-- / PROGRESS BAR -->
<router-outlet></router-outlet>
